import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  renderNumericField as RenderNumericField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  ManualPaymentType,
  postEmployerRefund,
  PostEmployerRefundParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import { invoiceableRecord } from './InvoiceableTable'
import dateTime from '../../../utils/dateTime'
import perms from '../../../utils/perms'

type props = {
  data: invoiceableRecord
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

export default function EmployerRefund({
  data,
  onClose,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postEmployerRefundParams, setPostEmployerRefundParams] =
    useState<PostEmployerRefundParams>(initialPostEmployerRefundParams())
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )

  useEffect(() => {
    setPostEmployerRefundParams(initialPostEmployerRefundParams())
  }, [isOpen])

  const setter = ({ name, value }: SetterArgs) => {
    const newValue =
      name === 'checkNumber' ? (value === null ? null : Number(value)) : value
    setPostEmployerRefundParams((curr: PostEmployerRefundParams) => ({
      ...curr,
      [name]: newValue,
    }))
  }

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  function initialPostEmployerRefundParams(): PostEmployerRefundParams {
    return {
      zClaimIDs: data.ZClaimIDs,
      manualPayType: ManualPaymentType.CHECK,
      paymentAmount: data.InvoiceTotal.replace('-', ''),
      paymentDate: dateTime.now().format(dateTime.formats.ISODate),
      checkNumber: null,
      achInfo: null,
      notes: null,
    }
  }

  function doEmployerRefund() {
    if (!hasPerms) {
      showSnackbar('Insufficient permissions', SnackbarTypeWarning)
      return
    }

    setIsWorking(true)
    let params: PostEmployerRefundParams = postEmployerRefundParams

    postEmployerRefund(params)
      .then((res: any) => {
        showSnackbar(`Employer refund posted OK`, SnackbarTypeSuccess)
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting employer refund',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        disabled={!hasPerms}
        variant="outlined"
        color="secondary"
        onClick={() => {
          setIsOpen(true)
        }}>
        Issue Refund
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>Employer Refund: {data.EmployerName}</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="paymentDate"
                  label="Payment Date"
                  value={postEmployerRefundParams.paymentDate}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="h6">
                  {formatAmount(data.InvoiceTotal.replace('-', ''))}
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <RadioGroup
                    row
                    name="manualPayType"
                    value={postEmployerRefundParams.manualPayType}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      setter({ name: ev.target.name, value: ev.target.value })
                      if (ev.target.value !== ManualPaymentType.CHECK) {
                        setter({ name: 'checkNumber', value: null })
                      }
                    }}>
                    <FormControlLabel
                      value={ManualPaymentType.ACH}
                      control={<Radio />}
                      label="ACH"
                    />
                    <FormControlLabel
                      value={ManualPaymentType.CHECK}
                      control={<Radio />}
                      label="Check"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <RenderNumericField
                  name="checkNumber"
                  label="Check Number"
                  value={postEmployerRefundParams.checkNumber || ''}
                  opts={{
                    hidden:
                      postEmployerRefundParams.manualPayType !==
                      ManualPaymentType.CHECK,
                  }}
                  setter={setter}
                />
                <RenderTextField
                  name="achInfo"
                  label="ACH Info"
                  value={postEmployerRefundParams.achInfo}
                  opts={{
                    hidden:
                      postEmployerRefundParams.manualPayType !==
                      ManualPaymentType.ACH,
                  }}
                  setter={setter}
                />
              </Grid>

              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postEmployerRefundParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking ||
                    !postEmployerRefundParams.paymentDate ||
                    !postEmployerRefundParams.paymentAmount ||
                    (postEmployerRefundParams.manualPayType ===
                      ManualPaymentType.CHECK &&
                      !postEmployerRefundParams.checkNumber) ||
                    (postEmployerRefundParams.manualPayType ===
                      ManualPaymentType.ACH &&
                      !postEmployerRefundParams.achInfo)
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doEmployerRefund}>
                  Issue Refund
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
