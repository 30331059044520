import React, { useEffect, useMemo, useState } from 'react'
import { Card, Container, Typography } from '@material-ui/core'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import { renderTextField as RenderTextField } from '../../../components/Inputs/standard'
import DataTable from '../../../components/DataTable/DataTable'
import { getPayable } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../components/DesignSuite2023'

export enum PayableStatus {
  BilledCollected = 'billed_collected',
  BilledUncollected = 'billed_uncollected',
  Unbilled = 'unbilled',
  EopOnly = 'eop_only',
  DoNotInvoice = 'do_not_invoice',
}

export function getPayableStatusLabel(value: PayableStatus): string {
  switch (value) {
    case PayableStatus.BilledCollected:
      return 'Billed Collected'
    case PayableStatus.BilledUncollected:
      return 'Billed Uncollected'
    case PayableStatus.Unbilled:
      return 'Unbilled'
    case PayableStatus.EopOnly:
      return 'EOP Only'
    case PayableStatus.DoNotInvoice:
      return 'Do Not Invoice'
    default:
      return value
  }
}

export enum payablePayeeType {
  All = 'all',
  BillingEntity = 'billing_entity',
  Partner = 'partner',
}

export function getPayablePayeeTypeDisplay(t: payablePayeeType): string {
  switch (t) {
    case payablePayeeType.All:
      return 'all'
    case payablePayeeType.BillingEntity:
      return 'billing entity'
    case payablePayeeType.Partner:
      return 'partner'
    default:
      return 'Unknown'
  }
}

export interface payableRecordDetail {
  ZClaimID: number
  ZClaimAmount: string
  IsReversal: boolean
  FundingInfos: string[]
}

export interface payableRecord {
  PayEntityID: number
  PayEntityName: string
  PayEntityType: payablePayeeType
  PayEntityAddress: string
  ClaimCount: number
  PayableStatus: string
  LastCollectedOn: string | null
  Amount: string
  Details: payableRecordDetail[]
}

interface payableDetailProps {
  payEntityID: number
  payableStatus: PayableStatus
  doClose?(): void
}

const PaymentDetailDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`
const claimColumns = {
  ZClaimID: {
    name: 'ZClaimID',
    details: {
      dataFormat(_: any, row: payableRecordDetail): JSX.Element {
        return (
          <span>
            {row.ZClaimID}{' '}
            {row.IsReversal && <span style={{ color: 'red' }}>[Reversal]</span>}
          </span>
        )
      },
    },
  },
  FundingInfos: {
    name: 'Funding Information',
    details: {},
  },
  ZClaimAmount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: payableRecordDetail): JSX.Element {
        return (
          <span style={{ color: row.IsReversal ? 'red' : 'inherit' }}>
            {formatAmount(row.ZClaimAmount.toString())}
          </span>
        )
      },
    },
  },
}

export default function PayableDetail({
  payEntityID,
  payableStatus,
  doClose,
}: payableDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null

  const [data, setData] = useState<payableRecord>({
    PayEntityID: 0,
    PayEntityName: '',
    PayEntityType: payablePayeeType.All,
    PayEntityAddress: '',
    ClaimCount: 0,
    PayableStatus: '',
    LastCollectedOn: '',
    Amount: '',
    Details: [],
  })

  const sorted = useMemo(() => {
    return data.Details.sort((a, b) => a.ZClaimID - b.ZClaimID)
  }, [data.Details])
  const [filteredRows, setFilteredRows] = useState<payableRecordDetail[]>([])
  const [search, setSearch] = useState('')
  const [paginatedRows, setPaginatedRows] = useState<payableRecordDetail[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)
  const { catchAPIError } = useErrorHandlers()
  const [isWorking, setIsWorking] = useState(true)

  useEffect(() => {
    paginateFilteredRows()
  }, [page, pageSize, filteredRows])

  useEffect(() => {
    if (!search) {
      setFilteredRows(sorted)
      return
    }
    setFilteredRows(
      sorted.filter((item) =>
        item.ZClaimID.toString().includes(search.toLowerCase())
      )
    )
  }, [search])

  useEffect(() => {
    getData()
  }, [payEntityID, payableStatus])

  function getData() {
    setIsWorking(true)
    return getPayable(payEntityID, payableStatus)
      .then((res: any) => {
        setData(res.Data)
        setPaginatedRows(res.Data.Details)
        setFilteredRows(res.Data.Details)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get payable data',
        })
      )
      .finally(() => setIsWorking(false))
  }

  function paginateFilteredRows() {
    setPaginatedRows(
      filteredRows.slice(page * pageSize - pageSize, page * pageSize)
    )
  }
  function handlePageChange(value: any) {
    setPage(value.page)
  }

  function handlePageSizeChange(value: any) {
    setPageSize(value.pageSize)
  }
  return (
    <Container>
      <Typography component="div" variant="h5" style={{ marginTop: '16px' }}>
        {data.PayEntityName}
      </Typography>
      <Typography>{data.PayEntityAddress}</Typography>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Claim Count" value={data.ClaimCount} />
        <ChipInformationDisplay
          label="Payable Status"
          value={getPayableStatusLabel(data.PayableStatus as PayableStatus)}
        />
        <ChipInformationDisplay
          label="Amount"
          value={formatAmount(data.Amount)}
          opts={{
            style: { color: parseFloat(data.Amount) < 0 ? 'red' : 'inherit' },
          }}
        />
      </PaymentDetailDiv>

      <Card variant="outlined">
        <RenderTextField
          name="ZClaimID"
          label="ZClaimID"
          value={search}
          setter={({ value }) => setSearch(value)}
          opts={{ style: { width: '150px', marginLeft: '8px' } }}
          use2023Styles
        />

        {!isWorking && (
          <DataTable
            columns={claimColumns}
            data={paginatedRows}
            initPage={1}
            initPageSize={pageSize}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={(v: any) => handlePageSizeChange(v)}
            count={filteredRows.length}
          />
        )}
      </Card>
      {isWorking && (
        <>
          &nbsp;
          <DesignSuite2023.LoadingSpinner size={100} />
        </>
      )}
    </Container>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
