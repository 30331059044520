/*
This old bag of a component seriously needs a refactor. With the updates to the new
UI, there's no good way for an old class-based component to implement the
useSetFrameHeaderMid hook to control content in the header bar. En-lieu of rewriting
this whole thing, the peer file (index.tsx) *wraps* this and passes in the controls to make
it work for now (TLDR; hacky af).
*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { MonthDayInput } from '../../../components/Inputs'
import { EmployerLogoPicker } from '../EmployerLogoPicker'
import * as DesignSuite2023 from '../../../components/DesignSuite2023'
import CopyIcon from '../../../components/CopyIcon'
import { canExecEngineering } from '../../../utils/perms'
import utils from '../../../utils'
import queryString from 'query-string'
import _ from 'lodash'
import perms from '../../../utils/perms'

import Done from '@material-ui/icons/Done'
import Error from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import NotesField from '../../../components/NotesField'
import classNames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import BillingConfig from '../BillingConfig'
import DetailView, {
  defaultStyleCreator,
} from '../../../components/DetailView/DetailView'
import {
  renderSelectFieldWithTooltip,
  renderTextFieldWithTooltip,
} from '../../ViewHelpers'
import Models from '../../../models'
import * as ContactTable from '../../Contacts/ContactTable'
import EmployerEligibilityFileConfigsTableContainer from '../EmployerEligibilityFileConfigsTableContainer'
import EmployerFileTableContainer from '../EmployerFileTableContainer'
import TeamZeroUsersTable from '../TeamZeroUsersTable'
import ImportFileEmails from '../FileImporters/FileEmails'
import ImportFileZipCodes from '../FileImporters/FileZipCodes'
import * as StopLossTable from '../../StopLoss/StopLossTable'
import * as MarketingActivityTable from '../MarketingActivity/Table'
import DialogManageMarketingActivity from '../MarketingActivity/DialogManageMarketingActivity'
import {
  clearSaveResult,
  EmployerActions,
  EngineeringActions,
} from '../../../actions'
import NetworksAutocomplete from '../../Networks/NetworksAutocomplete'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import dateTime from '../../../utils/dateTime'
import EmployerPlanDetail from '../EmployerPlan/Detail'
import LoaContacts from './LoaContacts'
import UnifiedInvoices from './UnifiedInvoices'

const {
  saveNewEmployer,
  deleteEmployer,
  getPlanExclusions,
  getEmployerById,
  getNewEmployer,
  getFilesForEmployer,
  DELETE_EMPLOYER,
  SAVE_NEW_EMPLOYER,
  updateEmployerPlanExclusions,
  updateEmployerCore,
  updateEmployerDetail,
  getEmployerContactTypes,
  saveNewEmployerContact,
} = EmployerActions

const ELIGIBILITY_TAB = 4
const ELIG_FILE_CONFIG_CHILD_TAB = 4

const Layout = styled.div`
  max-width: 1500px;

  .MuiFormControl-root,
  .MuiFormControl-marginDense,
  .MuiInputBase-root {
    width: 100% !important;
  }

  .MuiFormControl-marginDense {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`

const PlanCard = styled(Card)`
  margin-bottom: 20px;
`

const NetworkChip = styled(Chip)`
  margin-right: 5px;
`

const DisabledText = styled(Typography)`
  color: #949494;
`

const EditNetworks = styled(EditIcon)`
  cursor: pointer;
  color: #337ab7;
  font-size: 20px;
  margin-left: 5px;
`

const NetworksCompleteContainer = styled(DialogContent)`
  width: 600px;
`

const ChipContainer = styled(Grid)`
  margin-top: 10px;
`

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)
  const classes = {
    dialog: {
      '& > div': {
        overflowY: 'visible',
      },
    },
    notesField: {
      width: 500,
    },
    notReady: {
      padding: '1rem 0',
    },
    exclusionsTable: {
      marginTop: '1rem',
    },
    exclusionsRadioLabel: {
      margin: 0,
      '& > span': {
        padding: '0.25rem 0',
      },
    },
    suggestedPresets: {
      position: 'relative',
      top: '-5px',
      '& > span': {
        display: 'inline-block',
        paddingRight: '0.25rem',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }

  return Object.assign(classes, defaults)
}

class EmployerDetail extends DetailView {
  constructor(props) {
    super(props)
    const { match } = props
    this.refMarketingActivityTbl = React.createRef()
    this.refMarketingActivityDialog = React.createRef()
    this.initialState = {
      id: match.params.id * 1,
      fields: Object.assign({}, Models.Employer.editFields),
      confirmDelete: false,
      confirmTermination: false,
      confirmActivation: false,
      confirmUnsavedChanges: false,
      childTabValue: 0,
      mainTabValue: 0,
      saving: false,
      loading: true,
      loadingFiles: true,
      // loadingInvoices: true,
      requestedTerminationDate: dateTime.now().format(dateTime.formats.ISODate),
      requestedLaunchDate: dateTime.now().format(dateTime.formats.ISODate),
      exclusionsList: {},
      canDoEngineeringOnlyActions: canExecEngineering() || canExecETL(),
      updateDialogOpen: false,
      updatingEmployerCore: false,
      updateEmployerCoreError: null,
      updatingEmployerDetail: false,
      updateEmployerDetailError: null,
      updatingPlanExclusions: false,
      updatePlanExclusionError: null,
      emailImportResult: null,
      emailImportError: null,
      emailImportProcessing: false,
      planRenewalMonthDay: '',
      deductibleStartMonthDay: '',
      showNetworksModal: false,
    }

    this.state = this.initialState
    this.validators = Object.freeze(Models.Employer.validators)

    this.mainTabs = {
      details: 0,
      core: 1,
      billing: 2,
      exclusions: 3,
      eligibility: 4,
      imports: 5,
      employerPlan: 6,
    }
    this.childTabs = {
      contacts: 0,
      files: 1,
      invoices: 2,
      teamzero: 3,
      eligibilityFileConfigs: 4,
      stopLoss: 5,
      marketingActivity: 6,
    }
  }

  componentWillUnmount() {
    this.props.getNewEmployer() // clear out old selected employer
    this.props.clearSaveResult()
  }

  componentDidMount() {
    const { match } = this.props
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)
    this.props.clearSaveResult()
    this.props.getEmployerContactTypes()

    if (match.params.id) {
      const ID = match.params.id * 1
      this.setState({
        fields: Object.assign({}, Models.Employer.editFields, {
          ID,
        }),
      })
      this.props.getEmployerById({ employerId: ID }).then((res) => {
        this.setState({
          loading: false,
          mainTabValue: +qsParams.active_main_tab || 0,
          childTabValue: +qsParams.active_child_tab || 0,
        })
      })
      this.props.getPlanExclusions({ employerId: ID }).then((res) => {
        this.setState({ exclusionsList: res.payload.Data, loading: false })
      })
    } else {
      this.setState({
        loading: false,
        loadingFiles: false,
        fields: Object.assign({}, Models.Employer.editFields),
      })
      this.props.getNewEmployer()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { employer, saveResult } = nextProps
    const { history } = this.props
    const { location } = history
    let qsParams = queryString.parse(location.search)
    if (qsParams.z) qsParams = utils.mergeParams(qsParams.z, qsParams)

    this.setState({
      mainTabValue: +qsParams.active_main_tab || 0,
      childTabValue: +qsParams.active_child_tab || 0,
    })

    if (
      employer.ID !== this.props.employer.ID ||
      employer.UpdatedAt !== this.props.employer.UpdatedAt
    ) {
      const newEmployer = Object.assign({}, this.state.fields, employer)
      this.props
        .getFilesForEmployer({ employerId: employer.ID })
        .then((res) => {
          this.setState({ loadingFiles: false })
        })
      const {
        PlanRenewalDay,
        PlanRenewalMonth,
        DeductibleStartDay,
        DeductibleStartMonth,
      } = employer
      const formattedPRDay = `${PlanRenewalDay < 10 ? '0' : ''}${PlanRenewalDay}`
      const formattedPRMonth = `${PlanRenewalMonth < 10 ? '0' : ''}${PlanRenewalMonth}`
      const formattedDSDay = `${DeductibleStartDay < 10 ? '0' : ''}${DeductibleStartDay}`
      const formattedDSMonth = `${DeductibleStartMonth < 10 ? '0' : ''}${DeductibleStartMonth}`
      this.setState({
        planRenewalMonthDay:
          PlanRenewalMonth === 0 ? '' : `${formattedPRMonth}/${formattedPRDay}`,
        deductibleStartMonthDay:
          DeductibleStartMonth === 0
            ? ''
            : `${formattedDSMonth}/${formattedDSDay}`,
        fields: newEmployer,
        originalFields: newEmployer,
        changedFields: {},
      }) // be mindful of autoGenFields
      this.props?.setHeaderTitle?.(this.generateTitle(newEmployer))
    }

    if (saveResult) {
      if (saveResult.type === DELETE_EMPLOYER) {
        this.setState({ confirmDelete: false })
        this.props.clearSaveResult()
        this.props.history.push(`/employers`)
      } else if (saveResult.type === SAVE_NEW_EMPLOYER) {
        this.props.clearSaveResult()
        this.props.history.push(`/employer/${employer.ID}`)
        this.setState({ id: employer.ID })
        this.props
          .getPlanExclusions({ employerId: employer.ID })
          .then((res) => {
            this.setState({ exclusionsList: res.payload.Data, loading: false })
          })
      } else {
        this.setState({ saving: false })
      }
    }
  }

  generateTitle = (emp) => {
    const Launch = emp.LaunchDate ? `| Launch: ${emp.LaunchDate} ` : ''
    const Term = emp.TerminationDate ? `| Term: ${emp.TerminationDate} ` : ''
    const PlanRenewal =
      emp.PlanRenewalDate && emp.PlanRenewalDate !== 'Invalid date'
        ? `| Plan Renewal: ${emp.PlanRenewalDate} `
        : ''

    return `${emp.Name} ${Launch}${Term}${PlanRenewal}`
  }

  handleTermination = () => {
    this.handleTerminationDateChange(this.state.requestedTerminationDate)
    this.setState({ confirmTermination: false }, () => {
      this.props?.setHeaderTitle?.(this.generateTitle(this.state.fields))
    })
  }

  handleActivation = () => {
    this.handleLaunchDateChange(this.state.requestedLaunchDate)
    this.setState({ confirmActivation: false }, () => {
      this.props?.setHeaderTitle?.(this.generateTitle(this.state.fields))
    })
  }

  showConfirmTermination = () => {
    this.setState({ confirmTermination: true })
  }

  showConfirmActivation = () => {
    this.setState({ confirmActivation: true })
  }

  handleCancelTermination = () => {
    this.setState({ confirmTermination: false })
  }

  handleCancelActivation = () => {
    this.setState({ confirmActivation: false })
  }

  handleDelete = () => {
    this.props.deleteEmployer({
      employerId: this.state.fields.ID,
    })
  }

  showConfirmDelete = () => {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete = () => {
    this.setState({ confirmDelete: false })
  }

  getTotalFee = () => {
    if (!this.state.fields) return '0 %'
    const { FractionalRateFarley, FractionalRateService } = this.state.fields

    let fee = FractionalRateService * 1
    if (this.state.fields.DoNotBillFarleyFeeToCustomer) {
      return `${fee} %`
    }
    fee = fee + FractionalRateFarley * 1
    return `${fee} %`
  }

  getTotalSalesCommission = () => {
    if (!this.state.fields) return '0 %'
    const { FractionalRateBroker, FractionalRateSales } = this.state.fields
    const commission = FractionalRateBroker * 1 + FractionalRateSales * 1
    return `${commission}%`
  }

  isValidToSave = () => {
    const { TIN, LOAProcessType, LOAProcessTypeNotes } = this.state.fields
    if (TIN && (!TIN.length > 0 || !utils.isValidTIN(TIN))) return false
    if (LOAProcessType === 'other' && !LOAProcessTypeNotes) return false

    return true
  }

  isValidTIN = () => {
    const { TIN } = this.state.fields

    const hasTIN = TIN && TIN.length > 0
    return !hasTIN || utils.isValidTIN(TIN)
  }

  handleSave = (e) => {
    if (!this.isValidToSave()) {
      this.setState({ showValidations: true })
      return
    }
    this.executeSave()
  }

  refetchEmployer = () => {
    this.props.getEmployerById({ employerId: this.state.id })
    this.setState({
      originalFields: Object.assign({}, this.state.fields),
      changedFields: {},
    })
  }

  executeSave = () => {
    const { fields } = this.state
    const { core, detail } = Models.Employer.splitCoreDetail(fields)
    this.setState({ saving: true, showValidations: false })

    const exclusionsToSave = Object.keys(this.state.exclusionsList).map((k) => {
      return this.state.exclusionsList[k]
    })
    const planRenewal = monthDayValueDeriver(this.state.planRenewalMonthDay)
    const deductibleStart = monthDayValueDeriver(
      this.state.deductibleStartMonthDay
    )

    const employerToSave = Object.assign({}, this.props.employer, fields, {
      ZeroCardId: parseInt(fields.ZeroCardId, 10),
      PlanRenewalMonth: planRenewal.month,
      PlanRenewalDay: planRenewal.day,
      DeductibleStartDay: deductibleStart.day,
      DeductibleStartMonth: deductibleStart.month,
    })

    if (employerToSave.ID) {
      const employerId = employerToSave.ID
      const detailToSave = Object.assign({}, detail, {
        PlanRenewalMonth: planRenewal.month,
        PlanRenewalDay: planRenewal.day,
        DeductibleStartDay: deductibleStart.day,
        DeductibleStartMonth: deductibleStart.month,
        LOAProcessTypeNotes:
          detail.LOAProcessType === 'other' ? detail.LOAProcessTypeNotes : null,
      })
      const coreToSave = Object.assign({}, core, {
        ZeroCardId: parseInt(fields.ZeroCardId, 10),
        LaunchDate: fields.LaunchDate
          ? dateTime.parse(fields.LaunchDate).format(dateTime.formats.ISODate)
          : null,
        TerminationDate: fields.TerminationDate
          ? dateTime
              .parse(fields.TerminationDate)
              .format(dateTime.formats.ISODate)
          : null,
        ConfigurableNetworks: fields.ConfigurableNetworks.map(
          (network) => network.ID
        ),
      })

      this.setState({
        updateDialogOpen: true,
        updatingEmployerCore: true,
        updateEmployerCoreError: null,
        updatingEmployerDetail: true,
        updateEmployerDetailError: null,
        updatingPlanExclusions: true,
        updatePlanExclusionError: null,
      })

      this.props
        .updateEmployerDetail({ employerId }, detailToSave)
        .then((res) => {
          const { error, errList } = res.payload
          console.debug('Error? ', errList, res.payload)
          this.setState({
            updatingEmployerDetail: false,
            updateEmployerDetailError: error ? errList.join(', ') : null,
          })

          // update the core after the detail if the user has access
          if (Models.Employer.canUpdateCore()) {
            this.props
              .updateEmployerCore({ employerId }, coreToSave)
              .then((res) => {
                const { error, errList } = res.payload
                this.setState({
                  updatingEmployerCore: false,
                  updateEmployerCoreError: error ? errList.join(', ') : null,
                })
                this.refetchEmployer()
              })
              .catch((err) => {
                this.setState({
                  updatingEmployerCore: false,
                  updateEmployerCoreError: err,
                })
              })
          } else {
            this.refetchEmployer()
          }
        })
        .catch((err) => {
          console.debug('Caught! ', err)
          this.setState({
            updatingEmployerDetail: false,
            updateEmployerDetailError: err,
          })
        })
      this.props
        .updateEmployerPlanExclusions({ employerId }, exclusionsToSave)
        .then((res) => {
          const { error, errList } = res.payload
          this.setState({
            updatingPlanExclusions: false,
            updatePlanExclusionsError: error ? errList.join(', ') : null,
          })
        })
        .catch((err) => {
          this.setState({
            updatingPlanExclusions: false,
            updatePlanExclusionsError: err,
          })
        })
      return
    }
    employerToSave.LaunchDate = fields.LaunchDate
      ? dateTime.parse(fields.LaunchDate).format(dateTime.formats.ISODate)
      : null
    this.props.saveNewEmployer(employerToSave)
  }

  handleLaunchDateChange = (date) => {
    this.setState({
      fields: {
        ...this.state.fields,
        LaunchDate: date,
      },
    })
  }

  handleTerminationDateChange = (date) => {
    this.setState({
      fields: {
        ...this.state.fields,
        TerminationDate: date,
      },
    })
  }

  recordTabChanged = (key, value) => {
    const { history } = this.props
    if (history) {
      const existingParams = queryString.parse(history.location.search)
      const stringified = queryString.stringify({
        ...existingParams,
        [key]: value,
      })
      history.push(`?${stringified}`)
    }
  }

  handleMainTabChange = (_e, value) => {
    this.recordTabChanged('active_main_tab', value)
    this.setState({ mainTabValue: value })

    if (value === ELIGIBILITY_TAB) {
      this.recordTabChanged('active_child_tab', ELIG_FILE_CONFIG_CHILD_TAB)
      this.setState({ childTabValue: ELIG_FILE_CONFIG_CHILD_TAB })
    }
  }

  handleChildTabChange = (_e, value) => {
    this.recordTabChanged('active_child_tab', value)
    this.setState({ childTabValue: value })

    if (value === ELIG_FILE_CONFIG_CHILD_TAB) {
      this.recordTabChanged('active_main_tab', ELIGIBILITY_TAB)
      this.setState({ mainTabValue: ELIGIBILITY_TAB })
    }
  }

  renderChildTabs = () => {
    if (!this.state.fields.ID) return <div />
    return (
      <div>
        <Tabs
          value={this.state.childTabValue}
          onChange={this.handleChildTabChange}
          indicatorColor="primary"
          textColor="inherit">
          {Models.Contact.hasAccess() && (
            <Tab value={this.childTabs.contacts} label="Contacts" />
          )}
          {Models.File.hasAccess() && (
            <Tab value={this.childTabs.files} label="Files" />
          )}
          {Models.Invoice.hasAccess() && (
            <Tab value={this.childTabs.invoices} label="Invoices" />
          )}
          {Models.Employer.hasTeamZeroUserAccess() && (
            <Tab value={this.childTabs.teamzero} label="teamZERO" />
          )}
          {Models.EligibilityFileConfig.hasAccess() && (
            <Tab
              value={this.childTabs.eligibilityFileConfigs}
              label="Eligibility File Configs"
            />
          )}
          {Models.StopLoss.hasAccess() && (
            <Tab
              data-testid="tab-employer-stoploss"
              value={this.childTabs.stopLoss}
              label="Stop Loss"
            />
          )}
          {Models.Employer.hasAccess() && ( // @todo: new permission
            <Tab value={this.childTabs.marketingActivity} label="Marketing" />
          )}
        </Tabs>
        <div className={this.props.classes.childContainer}>
          {this.renderChildTabContent()}
        </div>
      </div>
    )
  }

  handleSaveNewContact = (contact) => {
    return this.props.saveNewEmployerContact({
      employerId: Number(this.state.fields.ID),
      contact,
    })
  }

  renderChildTabContent = () => {
    const { ID } = this.state.fields
    if (!ID) return
    switch (this.state.childTabValue) {
      case this.childTabs.contacts:
        return (
          <ContactTable.Table
            passFilters={{ EmployerID: Number(ID), IncludeInactive: false }}
            handleSaveNewContact={this.handleSaveNewContact}
            contactTypes={this.props.contactTypes}
            LeftHeaderItems={
              <>
                <ContactTable.StandardFilterSearch />
                &nbsp;
                <ContactTable.ContactTypeTableFilter entityType="employer" />
                &nbsp;
                <ContactTable.SwitchInactive />
                <ContactTable.FilterIsPrimaryContact />
              </>
            }
          />
        )

      case this.childTabs.files:
        return <EmployerFileTableContainer id={ID} />

      case this.childTabs.invoices:
        return Models.Invoice.hasAccess() ? (
          <UnifiedInvoices EmployerID={+this.props.match.params.id} />
        ) : null

      case this.childTabs.teamzero:
        return <TeamZeroUsersTable employerID={ID} />

      case this.childTabs.eligibilityFileConfigs:
        return <EmployerEligibilityFileConfigsTableContainer employerID={ID} />

      case this.childTabs.stopLoss:
        return (
          <div style={{ marginTop: '30px' }}>
            <StopLossTable.Table
              passFilters={{ EmployerIDs: [ID] }}
              enableURLReflection={false}
            />
          </div>
        )

      case this.childTabs.marketingActivity:
        return (
          <>
            <MarketingActivityTable.Table
              employerID={ID}
              ref={this.refMarketingActivityTbl}
              dialogRef={this.refMarketingActivityDialog}
              passFilters={{ EmployerIDs: [ID] }}
              LeftHeaderItems={
                <>
                  <MarketingActivityTable.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <DialogManageMarketingActivity
                    employerID={ID}
                    ref={this.refMarketingActivityDialog}
                    onSaved={() =>
                      this.refMarketingActivityTbl?.current?.refresh()
                    }
                  />
                </>
              }
            />
          </>
        )
      default:
        return null
    }
  }

  renderMainTabContent = () => {
    if (this.state.mainTabValue === this.mainTabs.details) {
      return this.renderDetail()
    } else if (this.state.mainTabValue === this.mainTabs.core) {
      return this.renderCore()
    } else if (this.state.mainTabValue === this.mainTabs.billing) {
      return this.renderBillingContent()
    } else if (this.state.mainTabValue === this.mainTabs.employerPlan) {
      return this.renderEmployerPlanContent()
    } else if (this.state.mainTabValue === this.mainTabs.exclusions) {
      return this.renderExclusionsContent()
    } else if (this.state.mainTabValue === this.mainTabs.eligibility) {
      return this.renderEligibilityContent()
    } else if (this.state.mainTabValue === this.mainTabs.imports) {
      return this.renderImportsContent()
    }

    return <div />
  }

  renderCore = () => {
    const { classes } = this.props
    const { fields } = this.state
    const addingNewEmployer = !this.props.employer.ID
    const hasAccess = Models.Employer.canUpdateCore() || addingNewEmployer // either updating and you have access, or adding (and we assuming access)
    const LaunchDate = dateTime.parse(this.state.fields.LaunchDate)
    const TerminationDate = dateTime.parse(this.state.fields.TerminationDate)
    const displayAddress = fields
      ? fields.Address
        ? fields.Address.DisplayAddress
        : ''
      : ''

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} className={classes.fieldColumn}>
          <Card>
            <CardHeader
              title="Core Information"
              subheader="Name, settings and other details."
            />
            <CardContent>
              {renderTextFieldWithTooltip(
                {
                  name: 'Name',
                  label: 'Name',
                  setter: this.handleChangeDirectInput.bind(this),
                  value: this.state.fields.Name,
                  opts: {
                    autoFocus: true,
                    disabled: !hasAccess,
                  },
                },
                'Name is used in all occurrences in internal applications, and is the default for legal name and marketing name if they are not explicitly set.'
              )}
              {this.renderTextField('ZeroCardId', 'Quest/Labcard ID', {
                disabled: !hasAccess,
              })}
              {this.renderTextField('RxSenseCode', 'RxnGo ID', {
                disabled: !hasAccess,
              })}
              {this.renderTextField('VbaDivisionCode', 'VBA Division Code', {
                disabled: !hasAccess,
              })}
              {this.renderTextField('Hash', 'Employer Hash (System Use)', {
                disabled: true,
              })}
              {this.renderSwitchField('AllowTravel', 'Allow Travel', {
                disabled: !hasAccess,
              })}
              <Typography>
                Is Customer:{' '}
                {<strong>{fields && fields.IsCustomer ? 'Yes' : 'No'}</strong>}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.fieldColumnExpanded}>
          <Card>
            <CardHeader
              title={
                <span>
                  Address{' '}
                  {displayAddress ? (
                    <CopyIcon value={displayAddress || ''} />
                  ) : null}
                </span>
              }
              subheader="The Employer's primary address"
            />
            <CardContent>
              {this.renderNestedTextField('Address1', 'Address 1', 'Address', {
                disabled: !hasAccess,
                style: { width: 300 },
              })}
              {this.renderNestedTextField('Address2', 'Address 2', 'Address', {
                disabled: !hasAccess,
                style: { width: 300 },
              })}
              {this.renderNestedTextField('City', 'City', 'Address', {
                disabled: !hasAccess,
              })}
              {this.renderNestedTextField('State', 'State', 'Address', {
                disabled: !hasAccess,
              })}
              {this.renderNestedTextField('Zip', 'Zip', 'Address', {
                disabled: !hasAccess,
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card>
            <CardHeader title="Actions" />
            <CardContent>
              <Typography>
                Current Launch Date:{' '}
                {
                  <strong>
                    {dateTime.parse(LaunchDate).isEmpty()
                      ? 'Not planned'
                      : LaunchDate.format(dateTime.formats.AmericanDate)}
                  </strong>
                }
              </Typography>
              <Typography>
                Current Termination Date:{' '}
                {
                  <strong>
                    {dateTime.parse(TerminationDate).isEmpty()
                      ? 'Not planned'
                      : TerminationDate.format(dateTime.formats.AmericanDate)}
                  </strong>
                }
              </Typography>
              {this.renderTextField('TerminationReason', 'Termination Reason', {
                placeholder: 'Why are they terminating?',
                multiline: true,
                rows: 4,
              })}
              <Button
                disabled={!hasAccess}
                className={classes.otherActionButton}
                color="primary"
                variant="outlined"
                onClick={this.showConfirmActivation}>
                {dateTime.parse(LaunchDate).isEmpty()
                  ? 'Activate'
                  : 'Change Activation Date'}
              </Button>
              <Button
                disabled={!hasAccess}
                className={classes.otherActionButton}
                color="secondary"
                variant="outlined"
                onClick={this.showConfirmTermination}>
                {dateTime.parse(TerminationDate).isEmpty()
                  ? 'Terminate'
                  : 'Change Termination Date'}
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    )
  }

  handleTierChange = ({ name, value }) => {
    this.handleSelectChange('Tier')({ target: { value } })
    this.setState({
      fields: {
        ...this.state.fields,
        Tier: value,
      },
    })
  }

  renderDetail = () => {
    const { fields } = this.state
    const { classes } = this.props

    let tinValidationOpts
    if (this.state.showValidations) {
      if (!this.isValidTIN())
        tinValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText: 'Must be 9 digits',
        }
    }
    const handleBrokerEngagementChange = (_, value) => {
      this.setState({
        fields: {
          ...fields,
          BrokerEngagement: value,
        },
        changedFields: {
          ...this.state.changedFields,
          BrokerEngagement: value,
        },
      })
    }
    const handleClientEngagementChange = (_, value) => {
      this.setState({
        fields: {
          ...fields,
          ClientEngagement: value,
        },
        changedFields: {
          ...this.state.changedFields,
          ClientEngagement: value,
        },
      })
    }

    const tierTooltip =
      'Tier is an indication of level of service offered to an employer based on size and strategic importance.  1 is highest level of service.'
    const tierValues = [
      { label: '', value: null },
      { label: '3', value: 3 },
      { label: '2', value: 2 },
      { label: '1', value: 1 },
    ]

    return (
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Card>
              <CardHeader title="General" />
              <CardContent>
                <EmployerLogoPicker employerID={this.state.id} />
                {this.renderSwitchField(
                  'AllowLogoMemberMarketingUse',
                  'Allow member marketing use of logo'
                )}
                {this.renderSwitchField(
                  'AllowLogoZeroMarketingUse',
                  'Allow ZERO marketing use of logo'
                )}
                {renderTextFieldWithTooltip(
                  {
                    name: 'LegalName',
                    label: 'Legal Name',
                    setter: this.handleChangeDirectInput.bind(this),
                    value: this.state.fields.LegalName,
                  },
                  "When set, the legal name is used in place of 'name' on contracts and invoices."
                )}
                {this.renderTextField('Descr', 'Description', {
                  multiline: true,
                  rows: 3,
                  style: { width: '100%' },
                })}
                {this.renderTextField('Website', 'Website')}
                {this.renderTextField(
                  'EmployerExperienceRep',
                  'Employer Experience Rep'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'Tier',
                    label: 'Tier',
                    setter: this.handleTierChange,
                    value: this.state.fields.Tier,
                    items: tierValues,
                  },
                  tierTooltip
                )}
                {this.renderSelect(
                  'Industry',
                  'Industry',
                  Models.Employer.industryOptions
                )}
                {this.renderTextField('PHANotes', 'PHA Notes', {
                  multiline: true,
                  rows: 3,
                  style: { width: '100%' },
                })}
                {this.renderSelect(
                  'LOAProcessType',
                  'LOA Process Type',
                  Models.Employer.loaProcessTypeOptions
                )}
                {this.state.fields.LOAProcessType ===
                'below 30% savings ask client' ? (
                  <LoaContacts employerID={this.state.id} />
                ) : null}
                {this.state.fields.LOAProcessType === 'other'
                  ? this.renderNotesField({
                      label: 'LOA Process Type Notes',
                      name: 'LOAProcessTypeNotes',
                    })
                  : null}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PlanCard>
              <CardHeader title="Plan" />
              <CardContent>
                {this.renderTextField('TIN', 'TIN', { ...tinValidationOpts })}
                <MonthDayInput
                  label="Plan Renewal Month/Day"
                  onChange={(e) => {
                    this.setState({
                      planRenewalMonthDay: e.target.value,
                    })
                  }}
                  value={this.state.planRenewalMonthDay}
                />
                <MonthDayInput
                  label="Deductible Start Month/Day"
                  onChange={(e) => {
                    this.setState({
                      deductibleStartMonthDay: e.target.value,
                    })
                  }}
                  value={this.state.deductibleStartMonthDay}
                />
                {/* {this.renderSwitchField('PhysicalLabcards', 'Physical Labcards?')} */}
              </CardContent>
            </PlanCard>
            <Card>
              <CardHeader title="Email" />
              <CardContent>
                {renderTextFieldWithTooltip(
                  {
                    name: 'MarketingName',
                    label: 'Marketing Name',
                    setter: this.handleChangeDirectInput.bind(this),
                    value: this.state.fields.MarketingName,
                  },
                  "When set, the marketing name is used instead of 'name' when addressing members and employers directly."
                )}
                {this.renderSwitchFieldWithTooltip(
                  'AllowEligibilityEmails',
                  'Allow Eligibility Emails?',
                  {},
                  'Emails on the eligibility record'
                )}
                {this.renderSwitchFieldWithTooltip(
                  'AllowHistoricalEligEmails',
                  'Allow Historical Elig Emails?',
                  {},
                  'Emails from past eligibility records'
                )}
                {this.renderSwitchFieldWithTooltip(
                  'AllowReferralEmails',
                  'Allow Referral Emails?',
                  {},
                  'Emails from referrals in goZero'
                )}
                {this.renderSwitchFieldWithTooltip(
                  'AllowEmployerEmails',
                  'Allow Employer Emails?',
                  {},
                  'Emails from a list sent by the employer'
                )}
                {this.renderSwitchFieldWithTooltip(
                  'AllowMyZeroEmails',
                  'Allow MyZero Emails?',
                  {},
                  'Emails from myZero accounts and profile pages'
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card>
              <CardHeader title="Analytics" />
              <CardContent>
                {this.renderSelect(
                  'EmployerRelationship',
                  'Employer Relationship',
                  [
                    { label: 'Direct', value: 'Direct' },
                    { label: 'Broker Channel', value: 'Broker Channel' },
                    { label: 'Partner', value: 'Partner' },
                  ]
                )}
                {this.renderTextField('TPA', 'TPA')}
                {this.renderTextField('PrimaryCarrier', 'Primary Carrier')}
                {this.renderTextField(
                  'TelehealthProvider',
                  'Telehealth Provider'
                )}
                {this.renderTextField(
                  'DirectPrimaryCare',
                  'Direct Primary Care (DPC)'
                )}
                {this.renderSelect(
                  'EmployerSize',
                  'Employer Size',
                  Models.Employer.employerSizeOptions
                )}
                {this.renderTextField('ConciergeService', 'Concierge Service')}
                {this.renderNotesField()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card>
              <CardHeader title="Actions" />
              <CardContent>
                <Grid container direction="column">
                  <Grid container justify="space-around">
                    <Typography>Configurable Networks</Typography>
                    {Models.Employer.canUpdateCore() &&
                    Models.ConfigurableNetwork.hasAccess() ? (
                      <EditNetworks
                        onClick={() =>
                          this.setState({ showNetworksModal: true })
                        }
                      />
                    ) : null}
                  </Grid>
                  <ChipContainer container>
                    {!this.state.fields.ConfigurableNetworks.length ? (
                      <DisabledText>None</DisabledText>
                    ) : null}
                    {this.state.fields.ConfigurableNetworks.map((network) => (
                      <NetworkChip
                        key={network.ID}
                        label={network.Name}
                        variant="outlined"
                        color="primary"
                      />
                    ))}
                  </ChipContainer>
                </Grid>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardHeader
                title="Engagement Rankings"
                subheader="How engaged are our Client and Broker partners"
              />
              <CardContent>
                <Typography variant="subtitle1" id="client-engagement-label">
                  Client Engagement:{' '}
                  {fields.ClientEngagement === null
                    ? 'Not yet set'
                    : fields.ClientEngagement}
                </Typography>
                <Typography variant="caption">
                  {Models.Employer.getClientEngagementLabel(
                    fields.ClientEngagement
                  )}
                </Typography>
                <div className={classes.root}>
                  <Slider
                    classes={{ container: classes.slider }}
                    value={fields.ClientEngagement}
                    min={0}
                    max={3}
                    step={1}
                    aria-labelledby="client-engagement-label"
                    onChange={handleClientEngagementChange}
                  />
                </div>
                <Typography variant="subtitle1" id="broker-engagement-label">
                  Broker Engagement:{' '}
                  {fields.BrokerEngagement === null
                    ? 'Not yet set'
                    : fields.BrokerEngagement}
                </Typography>
                <Typography variant="caption">
                  {Models.Employer.getBrokerEngagementLabel(
                    fields.BrokerEngagement
                  )}
                </Typography>
                <div className={classes.root}>
                  <Slider
                    classes={{ container: classes.slider }}
                    value={fields.BrokerEngagement}
                    min={0}
                    max={3}
                    step={1}
                    aria-labelledby="broker-engagement-label"
                    onChange={handleBrokerEngagementChange}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {this.renderNetworksModal()}
      </Layout>
    )
  }

  renderNetworksModal = () => (
    <Dialog open={this.state.showNetworksModal}>
      <DialogTitle>Configure Networks</DialogTitle>
      <NetworksCompleteContainer>
        <NetworksAutocomplete
          currentNetworks={this.state.fields.ConfigurableNetworks}
          handleChange={this.handleUpdateNetworks}
        />
      </NetworksCompleteContainer>
      <DialogActions>
        <Button
          onClick={() => this.setState({ showNetworksModal: false })}
          variant="contained"
          color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

  handleUpdateNetworks = (networks) => {
    this.setState({
      fields: { ...this.state.fields, ConfigurableNetworks: networks },
      changedFields: {
        ...this.state.changedFields,
        ConfigurableNetworks: networks,
      },
    })
  }

  renderEligibilityContent = () => {
    const { classes } = this.props
    const canMarkEligReprocessable = this.state.canDoEngineeringOnlyActions

    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <DesignSuite2023.Tooltip title="The employer has the following options available for employees/termed employees or retirees">
            <Typography variant="h5" width="100%">
              Employer Plan Details/Offerings{' '}
              <DesignSuite2023.CommonIcons.IconInfo />
            </Typography>
          </DesignSuite2023.Tooltip>
          {this.renderSwitchField('HasHSA', 'Has HSA')}
          <br />
          {this.renderSwitchField('HasPPO', 'Has PPO')}
          <br />
          {this.renderSwitchField('HasCobra', 'Has Cobra')}
          <br />
          {this.renderSwitchField('HasRetirees', 'Has Retirees')}
          <br />
          {this.renderEligibilityNotesField()}
        </Grid>
        {!canMarkEligReprocessable ? null : (
          <Grid item xs={12} sm={12}>
            <hr />
            <Button
              className={classes.button}
              variant="contained"
              style={{ whiteSpace: 'nowrap', margin: 0 }}
              onClick={this.handleMarkEmployerMembersReprocessable}>
              Mark All Members For Reprocessing
            </Button>
            <br />
            <br />
            <small>
              <i>Marking all members for reprocessing</i> will ensure that
              during the next batch eligibility process, all members are run
              through the full processing logic. <strong>Note:</strong> you can
              only see this if you have access to 'Engineering Only'
              permissions.
            </small>
            <hr />
          </Grid>
        )}
      </Grid>
    )
  }

  /**
   * Render the email import feature for uploading a CSV of emails to match to employer members
   */
  renderImportsContent = () => {
    const canUseImports = this.state.canDoEngineeringOnlyActions
    // This feature is gated for engineering users only
    if (!canUseImports) {
      return <div />
    } // @todo SD-564

    return (
      <div>
        <ImportFileEmails employerID={Number(this.props.match.params.id)} />
        <hr />
        <ImportFileZipCodes employerID={Number(this.props.match.params.id)} />
        <hr />
      </div>
    )
  }

  handleMarkEmployerMembersReprocessable = () => {
    const employerId = _.get(this.props, 'employer.ID')
    if (!employerId) {
      return
    }
    EngineeringActions.markEmployerMembersReprocessable({ employerId })
      .then((res) => {
        alert('last_processed_md5 cleared for all employer member records OK.')
      })
      .catch((e) => {
        alert(
          'Failed unsetting last_processed_md5 for all employer member records!'
        )
        console.warn(e)
      })
  }

  renderBillingContent = () => {
    const { classes } = this.props

    if (!this.props.employer.ID) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className={classes.notReady}>
              Billing cannot be configured until this employer has been saved.
            </p>
          </Grid>
        </Grid>
      )
    }
    return (
      <BillingConfig
        employerID={this.state.id}
        employerName={this.state.fields.Name}
      />
    )
  }

  renderEmployerPlanContent = () => {
    const { classes } = this.props

    if (!this.props.employer.ID) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className={classes.notReady}>
              Plan details cannot be configured until this employer has been
              saved.
            </p>
          </Grid>
        </Grid>
      )
    }

    if (!this.state.canDoEngineeringOnlyActions) {
      return (
        <div style={{ padding: '1rem 0' }}>
          This page is currently restricted to engineering only.
        </div>
      )
    }

    return (
      <div style={{ padding: '1rem 0' }}>
        <EmployerPlanDetail employerID={this.state.id} />
      </div>
    )
  }

  renderExclusionsContent = () => {
    const { classes } = this.props

    if (!this.props.employer.ID) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className={classes.notReady}>
              Exclusions cannot be configured until this employer has been
              saved.
            </p>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <table
            className={classNames(
              classes.exclusionsTable,
              'table table-hover table-bordered'
            )}>
            <thead>
              <tr>
                <th>Active</th>
                <th>Description</th>
                <th style={{ width: '99%' }}>Note</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.state.exclusionsList).map((id) => {
                const excl = this.state.exclusionsList[id]
                return (
                  <tr key={id}>
                    <td>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="status"
                          name={id}
                          value={
                            `${excl.Status}` /* must be a string for the radio component */
                          }
                          onChange={this.handleExclusionChangeActive}>
                          <FormControlLabel
                            className={classes.exclusionsRadioLabel}
                            value="0"
                            control={<Radio color="default" />}
                            label="Off"
                          />
                          <FormControlLabel
                            className={classes.exclusionsRadioLabel}
                            value="1"
                            control={<Radio color="primary" />}
                            label="Active"
                          />
                          <FormControlLabel
                            className={classes.exclusionsRadioLabel}
                            value="2"
                            control={<Radio color="secondary" />}
                            label="Custom"
                          />
                        </RadioGroup>
                      </FormControl>
                    </td>
                    <td>{excl.Descr}</td>
                    <td>
                      {excl.Status === 2 ? (
                        <NotesField
                          id="excl-change-notes"
                          label="Notes"
                          placeholder="Enter exclusion notes here"
                          rows="4"
                          rowsMax="8"
                          value={excl.Note || ''}
                          onChange={this.handleExclusionChangeNotes}
                          name={id}
                          margin="none"
                          multiline
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : (
                        '(not applicable)'
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          {this.renderExclusionNotesField()}
        </Grid>
      </Grid>
    )
  }

  handleExclusionChangeActive = (ev) => {
    const id = +ev.target.name
    this.setState({
      exclusionsList: {
        ...this.state.exclusionsList,
        [id]: {
          ...this.state.exclusionsList[id],
          Status: +ev.target.value,
        },
      },
    })
  }

  handleExclusionChangeNotes = (ev) => {
    const id = +ev.target.name
    this.setState({
      exclusionsList: {
        ...this.state.exclusionsList,
        [id]: {
          ...this.state.exclusionsList[id],
          Note: ev.target.value,
        },
      },
    })
  }

  renderExclusionNotesField = () => {
    return (
      <NotesField
        id="exclusion-notes"
        label="Exclusion Notes"
        multiline
        placeholder="Enter exclusion notes here"
        rows="8"
        rowsMax="12"
        value={this.state.fields.ExclusionNotes}
        onChange={this.handleChange}
        name="ExclusionNotes"
        margin="normal"
        fullWidth
      />
    )
  }

  renderBillingNotesField = (opts = {}) => {
    return (
      <NotesField
        {...opts}
        id="billing-notes"
        label="Billing Notes"
        multiline
        placeholder="Enter billing notes here"
        rows="8"
        rowsMax="12"
        className={this.props.classes.notesField}
        value={this.state.fields.BillingNotes}
        onChange={this.handleChange}
        name="BillingNotes"
        margin="normal"
      />
    )
  }

  renderEligibilityNotesField = () => {
    return (
      <NotesField
        id="eligibility-notes"
        label="General Employer Eligibility Notes"
        multiline
        placeholder="Enter elig notes here"
        rows="8"
        rowsMax="12"
        className={this.props.classes.notesField}
        value={this.state.fields.EligibilityNotes}
        onChange={this.handleChange}
        name="EligibilityNotes"
        margin="normal"
      />
    )
  }

  renderUpdatingDialog = () => {
    const { classes } = this.props
    const { updateDialogOpen } = this.state
    const {
      updatingEmployerCore,
      updatingEmployerDetail,
      updatingPlanExclusions,
    } = this.state
    const {
      updateEmployerDetailError,
      updateEmployerCoreError,
      updatePlanExclusionError,
    } = this.state
    const canUpdateCore = Models.Employer.canUpdateCore()

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={updateDialogOpen}
          onClose={() => this.setState({ updateDialogOpen: false })}>
          <DialogTitle>Update Status</DialogTitle>
          <DialogContent>
            {canUpdateCore ? (
              <Typography type="body">
                {updatingEmployerCore ? (
                  <CircularProgress />
                ) : updateEmployerCoreError ? (
                  <Error fontSize="large" />
                ) : (
                  <Done fontSize="large" />
                )}{' '}
                Updating Employer Core
              </Typography>
            ) : null}
            <Typography type="body">
              {updatingEmployerDetail ? (
                <CircularProgress />
              ) : updateEmployerDetailError ? (
                <Error fontSize="large" />
              ) : (
                <Done fontSize="large" />
              )}{' '}
              Updating Employer Detail
            </Typography>
            <Typography type="body">
              {updatingPlanExclusions ? (
                <CircularProgress />
              ) : updatePlanExclusionError ? (
                <Error fontSize="large" />
              ) : (
                <Done fontSize="large" />
              )}{' '}
              Updating Employer Plan Exclusions
            </Typography>
            {updateEmployerDetailError || null}
            {updateEmployerCoreError || null}
            {updatePlanExclusionError || null}
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ updateDialogOpen: false })
                }}
                color="primary">
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  renderConfirmActivationDialog = () => {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={this.state.confirmActivation}
          onClose={this.handleCancelActivation}>
          <DialogTitle>Confirm Activation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to activate {this.state.fields.Name} employer on the
              selected date. Would you like to proceed?
            </DialogContentText>
            <br />
            <ManagedDateInput // @todo: SD-2055
              name="ActivationDate"
              label="Activation Date"
              value={this.state.requestedLaunchDate}
              setter={({ name, value }) => {
                this.setState({ requestedLaunchDate: value })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelActivation} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleActivation} color="primary">
              Activate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderConfirmTerminationDialog = () => {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={this.state.confirmTermination}
          onClose={this.handleCancelTermination}>
          <DialogTitle>Confirm Termination</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to terminate {this.state.fields.Name} employer on
              the selected date. Would you like to proceed?
            </DialogContentText>
            <br />
            <ManagedDateInput
              name="TerminationDate"
              label="Termination Date"
              value={this.state.requestedTerminationDate}
              setter={({ name, value }) => {
                this.setState({ requestedTerminationDate: value })
              }}
            />
            <br />
            {this.renderTextField('TerminationReason', 'Termination Reason', {
              placeholder: 'Why are they terminating?',
              multiline: true,
              rows: 4,
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelTermination} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleTermination} color="primary">
              Terminate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderConfirmDeleteDialog = () => {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={this.state.confirmDelete}
          onClose={this.handleCancelDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to delete {this.state.fields.Name} employer. Are you
              sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <Button
                  autoFocus
                  variant="outline"
                  onClick={this.handleCancelDelete}
                  color="primary">
                  Cancel
                </Button>
              }
              right={
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  color="secondary">
                  Delete it!
                </Button>
              }
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  showSaveAndDeleteBtns = () => {
    if (this.state.mainTabValue === this.mainTabs.employerPlan) return false
    return true
  }

  renderContent = () => {
    const { classes } = this.props
    if (this.state.loading)
      return <CircularProgress className={classes.progress} />
    const canUseImports = this.state.canDoEngineeringOnlyActions

    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <Tabs
            value={this.state.mainTabValue}
            onChange={this.handleMainTabChange}
            indicatorColor="primary"
            textColor="inherit">
            <Tab value={this.mainTabs.details} label="Details" />
            <Tab value={this.mainTabs.core} label="Core" />
            <Tab value={this.mainTabs.billing} label="Billing" />
            <Tab value={this.mainTabs.employerPlan} label="Plan Design" />
            <Tab
              value={this.mainTabs.exclusions}
              label="Exclusions" // @todo: attach to employer plan!
            />
            <Tab value={this.mainTabs.eligibility} label="Eligibility" />
            {canUseImports ? (
              <Tab value={this.mainTabs.imports} label="Imports" />
            ) : null}
          </Tabs>
          {this.renderMainTabContent()}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} />
            {this.state.mainTabValue !== this.mainTabs.billing && (
              <>
                <Grid item sm={12} lg={7}>
                  {this.props.saveResult && this.props.saveResult.error && (
                    <Typography type="title" className={classes.errorMsg}>
                      {this.props.saveResult.message}
                    </Typography>
                  )}
                </Grid>
                {this.showSaveAndDeleteBtns() && (
                  <Grid item xs={12} lg={5}>
                    <div className={classes.saveButtonContainer}>
                      <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={this.handleSave}>
                        Save Employer
                      </Button>
                      {this.state.fields.ID !== 0 && (
                        <Button
                          className={classes.button}
                          color="secondary"
                          variant="contained"
                          onClick={this.showConfirmDelete}>
                          Delete
                        </Button>
                      )}
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {this.props.employer &&
                    this.props.employer.ID &&
                    this.renderChanges()}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider className={classes.sectionDivider} />
            </Grid>
            <Grid item xs={12}>
              {this.renderChildTabs()}
            </Grid>
          </Grid>
          {this.props.employer &&
            this.props.employer.ID &&
            this.renderConfirmUnsavedChanges()}
          {this.renderConfirmDeleteDialog()}
          {this.renderConfirmTerminationDialog()}
          {this.renderConfirmActivationDialog()}
          {this.renderUpdatingDialog()}
        </Paper>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.getError
          ? this.renderError(this.props.getError.message)
          : this.renderContent()}
      </div>
    )
  }
}

EmployerDetail.propTypes = {
  employer: PropTypes.object.isRequired,
  getError: PropTypes.object,
}

function mapStateToProps(state) {
  const types = state.employers.contactTypes || []
  return {
    // invoices: state.employers.invoices || [],
    employer: state.employers.employer || {},
    exclusions: state.employers.exclusions || [],
    files: state.employers.files || [],
    saveResult: state.employers.saveResult,
    getError: state.employers.getError,
    getContactTypesError: state.employers.getContactTypesError,
    contactTypes: utils.getSelectOptions(types, {
      valueKey: 'ID',
      labelKey: 'Description',
    }),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveNewEmployer,
      deleteEmployer,
      getPlanExclusions,
      getEmployerById,
      getNewEmployer,
      getFilesForEmployer,
      clearSaveResult,
      updateEmployerCore,
      updateEmployerDetail,
      updateEmployerPlanExclusions,
      saveNewEmployerContact,
      getEmployerContactTypes,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EmployerDetail)

// utility function only used by this component (so far)
function monthDayValueDeriver(monthDay) {
  const nullval = { month: null, day: null }
  if (monthDay === '') {
    return nullval
  }
  const parts = monthDay.split('/')
  if (parts.length !== 2) {
    return nullval
  }

  return {
    month: parts[0] * 1,
    day: parts[1] * 1,
  }
}

function canExecETL() {
  return perms.has(perms.RoleTokens.PermETL)
}
