import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  SetterArgs,
  renderNotesField as RenderNotesField,
} from '../../../components/Inputs/standard'
import {
  postDoNotInvoice,
  postDoNotInvoiceParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'

interface props {
  disabled?: boolean
  zClaimIDs: number[]
  totalAmount: string
  onComplete?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

function initialPostDoNotInvoiceParams(): postDoNotInvoiceParams {
  return {
    zClaimIDs: [],
    notes: '',
  }
}

export default function ButtonDoNotInvoiceZClaims({
  disabled,
  zClaimIDs,
  totalAmount,
  onComplete,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postDoNotInvoiceParams, setPostDoNotInvoiceParams] =
    useState<postDoNotInvoiceParams>(initialPostDoNotInvoiceParams())

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setPostDoNotInvoiceParams((prevParams) => ({
      ...prevParams,
      zClaimIDs: zClaimIDs,
    }))
  }, [zClaimIDs])

  function doPostDoNotInvoice() {
    setIsWorking(true)

    postDoNotInvoice(postDoNotInvoiceParams)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPostDoNotInvoiceParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Mark selected claims as Do Not Invoice">
        <span>
          <Button
            disabled={isWorking || disabled}
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconFile />}
            onClick={() => {
              setIsOpen(true)
            }}>
            {`Do Not Invoice (${zClaimIDs.length}) $${totalAmount}`}
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>
          {' '}
          {`Do Not Invoice (${zClaimIDs.length}) ZClaims totaling $${totalAmount}`}
        </DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography>
                  This action will write off the unbilled balance for these
                  claims as not invoiceable. This does not affect the payable
                  status (they still may or may not be payable to the
                  providers).
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postDoNotInvoiceParams.notes}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {`ZClaimIDs: ${zClaimIDs}`}
                </Typography>
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking || !zClaimIDs.length || zClaimIDs.length === 0
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doPostDoNotInvoice}>
                  {`Do Not Invoice ${zClaimIDs.length} ZClaims`}
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
