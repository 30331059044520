import React, { useEffect, useRef, useState } from 'react'
import {
  invoiceableClaimTableRecord,
  StandardFilterSearch,
  Table,
} from './InvoiceableClaimTable'
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import ButtonDoNotInvoiceZClaims from './ButtonDoNotInvoiceZClaims'
import { invoiceableRecord, initialInvoiceableRecord } from './InvoiceableTable'
import { findZClaimInvoiceable } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

type props = {
  employerID: number
  zClaimIDs: number[]
}

export default function ViewInvoiceableClaim({
  employerID,
  zClaimIDs,
}: props): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<
    Array<invoiceableClaimTableRecord>
  >([])
  const refInvoiceableClaimTable = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<invoiceableRecord>(initialInvoiceableRecord)
  const [checkedAmount, setCheckedAmount] = useState<string>('0')
  const amount = data.InvoiceTotal
    ? parseFloat(data.InvoiceTotal.replace(/[^0-9.-]+/g, ''))
    : 0

  const style = amount < 0 ? { color: 'red' } : {}

  useEffect(() => {
    getData()
  }, [employerID])

  useEffect(() => {
    const total = checkedItems.reduce((sum, item) => {
      const amount = parseFloat(
        item.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, '')
      )
      return sum + amount
    }, 0)
    setCheckedAmount(total.toFixed(2))
  }, [checkedItems])

  function getData() {
    return findZClaimInvoiceable({ filter: { EmployerIDs: [employerID] } })
      .then((res: any) => {
        console.log(res.Data)
        if (res.Data && res.Data.length > 0) {
          setData(res.Data[0])
        } else {
          setData({
            ...initialInvoiceableRecord,
            EmployerLegalName: data.EmployerLegalName,
            EmployerQuickbooksHandle: data.EmployerQuickbooksHandle,
            EmployerName: data.EmployerName,
          })
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get zClaimInvoiceable data',
        })
      )
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader
            title={
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={9}>
                  <div>
                    <div>{data.EmployerLegalName}</div>
                    {data.EmployerLegalName !==
                      data.EmployerQuickbooksHandle && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerQuickbooksHandle}
                      </div>
                    )}
                    {data.EmployerLegalName !== data.EmployerName && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerName}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={'auto'}>
                  <Typography>{`Claims[${data.ClaimCount}]:`} </Typography>
                  <Typography>{`Fees:`}</Typography>
                  <Typography>{`Invoice:`}</Typography>
                </Grid>
                <Grid item xs={'auto'} style={{ textAlign: 'right', ...style }}>
                  <Typography>{`${formatAmount(data.ClaimTotal)}`}</Typography>
                  <Typography>{`${formatAmount(data.FeeTotal)}`}</Typography>
                  <Typography>
                    {`${formatAmount(data.InvoiceTotal)}`}
                  </Typography>
                </Grid>
              </Grid>
            }></CardHeader>
          <CardContent>
            <Table
              ref={refInvoiceableClaimTable}
              passFilters={{
                ZClaimIDs: zClaimIDs,
                DoNotInvoice: false,
              }}
              onCheckHandler={setCheckedItems}
              dataTableProps={{
                keyProp: 'ZClaimID',
              }}
              LeftHeaderItems={
                <StandardFilterSearch
                  autoFocus
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                />
              }
              RightHeaderItems={
                <ButtonDoNotInvoiceZClaims
                  disabled={checkedItems.length === 0}
                  zClaimIDs={checkedItems
                    .map((v: invoiceableClaimTableRecord) => v.ZClaimID)
                    .flat()}
                  onComplete={() => {
                    getData()
                    refInvoiceableClaimTable.current?.refresh()
                  }}
                  totalAmount={checkedAmount}
                />
              }
            />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
