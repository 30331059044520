import React, { useState } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  PayItems,
  postSendZelisPayments,
} from '../../../actions/ZClaimFundingActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'
import perms from '../../../utils/perms'

interface props {
  postSendZelisPaymentsParams: PayItems
  paymentCount: number
  paymentAmount: number
  onComplete?(): void
  disabled?: boolean
  isWorkingCallback?: (isWorking: boolean) => void
}

export default React.forwardRef(function ButtonDoZelisPayment(
  {
    postSendZelisPaymentsParams,
    paymentCount,
    paymentAmount,
    onComplete,
    disabled = false,
    isWorkingCallback,
  }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )
  const markDisabled = isWorking || disabled || !hasPerms

  function doPayment() {
    isWorkingCallback?.(true)
    setIsWorking(true)

    return postSendZelisPayments(postSendZelisPaymentsParams)
      .then(() => {
        showSnackbar('Payment completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed Payment',
        })
      )
      .finally(() => {
        isWorkingCallback?.(false)
        setIsWorking(false)
      })
  }

  return (
    <DesignSuite2023.Tooltip title="Payment selected items">
      <span>
        <Button
          ref={ref}
          disabled={markDisabled}
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          onClick={doPayment}>
          {'Zelis Pay' +
            (paymentCount > 0
              ? ` (${paymentCount}) ${formatAmount(paymentAmount)}`
              : '')}
          {isWorking && (
            <>
              &nbsp;
              <DesignSuite2023.LoadingSpinner size={20} show />
            </>
          )}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})

function formatAmount(amount: number): string {
  if (!amount || amount === 0) return '$0.00'
  return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
