import * as api from '../services/thezerocard/api-helper'

export const GET_ELIGIBILITY_FILE_CONFIGS = 'GET_ELIGIBILITY_FILE_CONFIGS'
export const GET_ELIGIBILITY_FILE_CONFIG = 'GET_ELIGIBILITY_FILE_CONFIG'
export const GET_NEW_ELIGIBILITY_FILE_CONFIG = 'GET_NEW_ELIGIBILITY_FILE_CONFIG'
export const PUT_ELIGIBILITY_FILE_CONFIG = 'PUT_ELIGIBILITY_FILE_CONFIG'
export const POST_ELIGIBILITY_FILE_CONFIG = 'POST_ELIGIBILITY_FILE_CONFIG'
export const GET_EMPLOYERS = 'GET_EMPLOYERS'
export const GET_PLAN_EXCLUSIONS = 'GET_PLAN_EXCLUSIONS'
export const GET_EMPLOYER_BY_ID = 'GET_EMPLOYER_BY_ID'
export const SAVE_NEW_EMPLOYER = 'SAVE_NEW_EMPLOYER'
export const DELETE_EMPLOYER = 'DELETE_EMPLOYER'
export const GET_FILES_FOR_EMPLOYER = 'GET_FILES_FOR_EMPLOYER'
export const GET_NEW_EMPLOYER = 'GET_NEW_EMPLOYER'
export const UPDATE_EMPLOYER_PLAN_EXCLUSIONS = 'UPDATE_EMPLOYER_PLAN_EXCLUSIONS'
export const GET_EMPLOYER_CONTACT_TYPES = 'GET_EMPLOYER_CONTACT_TYPES'
export const SAVE_NEW_EMPLOYER_CONTACT = 'SAVE_NEW_EMPLOYER_CONTACT'
export const MANAGE_TEAM_ZERO_USERS = 'MANAGE_TEAM_ZERO_USERS'
export const MANAGE_TEAM_ZERO_ADMIN_USERS = 'MANAGE_TEAM_ZERO_ADMIN_USERS'
export const SEND_TEAMZERO_CHANGE_PASSWORD_EMAIL =
  'SEND_TEAMZERO_CHANGE_PASSWORD_EMAIL'
export const GET_TEAM_ZERO_USERS = 'GET_TEAM_ZERO_USERS'
export const GET_USERS_WITH_ACCESS = 'GET_USERS_WITH_ACCESS'
export const UPDATE_EMPLOYER_CORE = 'UPDATE_EMPLOYER_CORE'
export const UPDATE_EMPLOYER_DETAIL = 'UPDATE_EMPLOYER_DETAIL'

export const GET_EMPLOYER_BILLING_CONFIG = 'GET_EMPLOYER_BILLING_CONFIG'
export const SAVE_EMPLOYER_BILLING_CONFIG = 'SAVE_EMPLOYER_BILLING_CONFIG'

export const IMPORT_EMPLOYER_MEMBER_EMAILS = 'IMPORT_EMPLOYER_MEMBER_EMAILS'
export const IMPORT_EMPLOYER_ZIP_CODES = 'IMPORT_EMPLOYER_ZIP_CODES'

const EMPLOYER_OWNER = 'employer'
const GooniesID = 198

export const getNewEmployer = () => {
  return {
    type: GET_NEW_EMPLOYER,
    payload: {},
  }
}

export const getAllEmployers = () => {
  return {
    type: GET_EMPLOYERS,
    payload: api.search('/employer', {
      filter: { noLimit: true, isCustomer: true, orIDs: [GooniesID] },
    }),
  }
}

export const getNewEligibilityFileConfig = (eligibilityFileConfig) => {
  return {
    type: GET_NEW_ELIGIBILITY_FILE_CONFIG,
    payload: eligibilityFileConfig,
  }
}

export const getEligibilityFileConfigs = ({ employerID }) => {
  return {
    type: GET_ELIGIBILITY_FILE_CONFIGS,
    payload: api.search('/eligibility_file_config', {
      filter: { employerID: employerID * 1 },
    }),
  }
}

export const getEligibilityFileConfig = ({ ID }) => {
  return {
    type: GET_ELIGIBILITY_FILE_CONFIG,
    payload: api.get(`/eligibility_file_config/${ID}`),
  }
}

export const putEligibilityFileConfig = ({ ID, body }) => {
  return {
    type: PUT_ELIGIBILITY_FILE_CONFIG,
    payload: api.put(`/eligibility_file_config/${ID}`, body),
  }
}

export const postEligibilityFileConfig = ({ body }) => {
  return {
    type: POST_ELIGIBILITY_FILE_CONFIG,
    payload: api.post(`/eligibility_file_config`, body),
  }
}

export const getPlanExclusions = (params) => {
  const { employerId } = params
  return {
    type: GET_PLAN_EXCLUSIONS,
    payload: api.get(`/employer/${employerId}/plan_exclusions`),
  }
}

// @todo: remove
export const getEmployerById = (params) => {
  const { employerId } = params
  return {
    type: GET_EMPLOYER_BY_ID,
    payload: api.get(`/employer/${employerId}`),
  }
}

export function getEmployerByIDV2(employerID) {
  return api.get(`/employer/${employerID}`)
}

export const updateEmployerPlanExclusions = (params, body) => {
  const { employerId } = params
  return {
    type: UPDATE_EMPLOYER_PLAN_EXCLUSIONS,
    payload: api.put(`/employer/${employerId}/plan_exclusions`, body),
  }
}

export const updateEmployerCore = (params, body) => {
  const { employerId } = params
  return {
    type: UPDATE_EMPLOYER_CORE,
    payload: api.put(`/employer/${employerId}/core`, body),
  }
}

export const updateEmployerDetail = (params, body) => {
  const { employerId } = params
  return {
    type: UPDATE_EMPLOYER_DETAIL,
    payload: api.put(`/employer/${employerId}/detail`, body),
  }
}

export const saveNewEmployer = (body) => {
  return {
    type: SAVE_NEW_EMPLOYER,
    payload: api.post('/employer', body),
  }
}

export const saveNewEmployerContact = ({ employerId, contact }) => {
  return {
    type: SAVE_NEW_EMPLOYER_CONTACT,
    payload: api.post(`/employer/${employerId}/contact`, contact),
  }
}

export const deleteEmployer = (params) => {
  const { employerId } = params
  return {
    type: DELETE_EMPLOYER,
    payload: api.del(`/employer/${employerId}`),
  }
}

export const getFilesForEmployer = (params) => {
  const { employerId } = params
  const qsParams = {
    employer_id: employerId,
    owner: EMPLOYER_OWNER,
  }

  return {
    type: GET_FILES_FOR_EMPLOYER,
    payload: api.get('/files', qsParams),
  }
}

export const getEmployerContactTypes = () => {
  return {
    type: GET_EMPLOYER_CONTACT_TYPES,
    payload: api.get('/contact_type/employer'),
  }
}

export const getUsersWithAccess = ({ employerID }) => {
  return {
    type: GET_USERS_WITH_ACCESS,
    payload: api.get(`/employer/${employerID}/users`),
  }
}

export const getTeamZeroUsers = ({ employerID }) => {
  return {
    type: GET_TEAM_ZERO_USERS,
    payload: api.get(`/employer/${employerID}/teamzero/users`),
  }
}

export const manageTeamZeroUsers = ({ employerID, body }) => {
  return {
    type: MANAGE_TEAM_ZERO_USERS,
    payload: api.put(`/employer/${employerID}/teamzero/users`, body),
  }
}

export const manageTeamZeroAdminUsers = ({ employerID, body }) => {
  return {
    type: MANAGE_TEAM_ZERO_ADMIN_USERS,
    payload: api.put(`/employer/${employerID}/teamzero/admin_users`, body),
  }
}

export const sendTeamZeroPasswordEmail = ({ employerID, body }) => {
  return {
    type: SEND_TEAMZERO_CHANGE_PASSWORD_EMAIL,
    payload: api.post(
      `/employer/${employerID}/teamzero/change_password_email`,
      body
    ),
  }
}

export const createTeamZeroUser = (params, body) => {
  return api.post(`/employer/${params.employerID}/teamzero/user`, body)
}

export const getEmployerBillingConfig = ({ employerID }) => {
  return {
    type: GET_EMPLOYER_BILLING_CONFIG,
    payload: api.get(`/employer/${employerID}/billing_config`),
  }
}

export const saveEmployerBillingConfig = ({ employerID, body }) => {
  return {
    type: SAVE_EMPLOYER_BILLING_CONFIG,
    payload: api.put(`/employer/${employerID}/billing_config`, body),
  }
}

export const importEmployerMemberEmails = ({ employerID, formData }) => {
  return {
    type: IMPORT_EMPLOYER_MEMBER_EMAILS,
    payload: api.postFormData(
      `/employer/${employerID}/import_employer_member_emails`,
      formData
    ),
  }
}

export const importEmployerZipCodes = ({ employerID, formData }) => {
  return {
    type: IMPORT_EMPLOYER_ZIP_CODES,
    payload: api.postFormData(
      `/employer/${employerID}/import_employer_member_zipcodes`,
      formData
    ),
  }
}

/*
// for when this gets converted to typescript
interface EmployerLogoResponse {
  EmployerID: number;
  ActiveLogoFileID: number;
  Logos: []EmployerLogo;
  Error: null | any;
}
*/

export const getEmployerLogos = ({ id }) => {
  return api
    .get(`/employer/${id}/logos`)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res.Data.Logos
    })
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed loading employer logos; please contact Engineering`
    })
}

export const putActiveLogo = ({ id, fileID }) => {
  return api
    .put(`/employer/${id}/active_logo_file/${fileID}`)
    .then((res) => {
      return true
    })
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed updating active logo; please contact Engineering`
    })
}

export const postLogoFile = ({ id }, body) => {
  const payload = Object.assign({}, body, { MakeActive: true })
  return api
    .post(`/employer/${id}/logo_file`, payload)
    .then((res) => res.Data)
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed saving logo file; please contact Engineering`
    })
}

export const addNewEmployerMember = ({ employerID, memberID }, body) => {
  return api.post(`/employer/${employerID}/member/${memberID}`, body)
}

export const getEmployerDivisions = (employerID) => {
  return api.get(`/employer/${employerID}/divisions`).then((res) => res.Data)
}

export const getEmployers = (payload) => {
  return api.search(`/employer`, payload)
}

export const getEmployerContactTypesV2 = () => {
  return api.get('/contact_type/employer')
}

export const getEmployerMarketingActivity = (payload) => {
  return api.search(`/employer_marketing_activity`, payload)
}

export const getEmployerMarketingActivityByID = (id) => {
  return api.get(`/employer_marketing_activity/${id}`)
}

export const postEmployerMarketingActivity = (payload) => {
  return api.post(`/employer/${payload.EmployerID}/marketing_activity`, payload)
}

export const putEmployerMarketingActivityByID = (payload) => {
  return api.put(`/employer_marketing_activity/${payload.ID}`, payload)
}

export const getEmployerMarketingActivityTypes = (payload) => {
  return api.get(`/employer_marketing_activity_types`, payload)
}

export function deleteEmployerMarketingActivityFile(id, fileID) {
  return api
    .del(`/employer_marketing_activity/${id}/file/${fileID}`)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getEmployerPlanByID(employerPlanID) {
  return api.get(`/employer/plan/${employerPlanID}`).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function postEmployerPlan(employerID, payload) {
  return api.post(`/employer/${employerID}/plan`, payload).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function putEmployerPlan(employerID, payload) {
  return api
    .put(`/employer/${employerID}/plan/${payload.ID}`, payload)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getEmployerPlanLimits(employerPlanID) {
  return api.get(`/employer/plan/${employerPlanID}/limits`).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function putEmployerPlanLimits(employerPlanID, payload) {
  return api
    .put(`/employer/plan/${employerPlanID}/limits`, payload)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findEmployerPlans(payload) {
  return api.search(`/employer/plans`, payload).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function bulkDeleteEmployerMarketingActivity(employerID, payload) {
  return api
    .put(`/employer/${employerID}/bulk_delete_marketing_activity`, payload)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function deactivateMembersForEmployer(employerId, payload) {
  return api
    .put(`/employer/${employerId}/deactivate_members`, payload)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res
    })
}
