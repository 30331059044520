import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  postReceiptReversal,
  PostReceiptReversalParams,
  ReceiptReversalReason,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'
import perms from '../../../utils/perms'

interface reverseReceiptProps {
  ReceiptID: number
  ReceiptDate: string | null
  PayerName: string
  ReceiptType: receiptType
  ReceiptAmount: string
}

type props = {
  data: reverseReceiptProps
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

export default function VoidReceipt({
  data,
  onClose,
}: props): React.ReactElement {
  function initialPostReceiptReversalParams(): PostReceiptReversalParams {
    return {
      reverseReceiptID: data.ReceiptID,
      reversalDate: dateTime.now().format(dateTime.formats.ISODate),
      reversalReason: ReceiptReversalReason.BLANK,
      notes: '',
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postReceiptReversalParams, setPostReceiptReversalParams] =
    useState<PostReceiptReversalParams>(initialPostReceiptReversalParams())
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )

  useEffect(() => {
    setPostReceiptReversalParams(initialPostReceiptReversalParams())
  }, [isOpen])

  const setter = ({ name, value }: SetterArgs) => {
    setPostReceiptReversalParams((curr: PostReceiptReversalParams) => ({
      ...curr,
      [name]: value,
    }))
  }

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  function doPostReceiptReversal() {
    setIsWorking(true)
    let params: PostReceiptReversalParams = postReceiptReversalParams

    postReceiptReversal(params)
      .then((res: any) => {
        showSnackbar(`Receipt reversal posted OK`, SnackbarTypeSuccess)
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt reversal',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        disabled={!hasPerms}
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => {
          setIsOpen(true)
        }}>
        Void Receipt
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>
          {data.PayerName}
          <Typography variant="subtitle1">
            Receipt Date: {dateTime.parse(data.ReceiptDate).format()} Receipt
            Type: {data.ReceiptType}
          </Typography>
          <Typography variant="subtitle1">
            Void the receipt and remove any funds that were applied.
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="reversalDate"
                  label="Reversal Date"
                  value={postReceiptReversalParams.reversalDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="h6">
                  {formatAmount(data.ReceiptAmount)}
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>Reason</FormLabel>
                  <RadioGroup
                    row
                    name="reversalReason"
                    value={postReceiptReversalParams.reversalReason}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      setter({ name: ev.target.name, value: ev.target.value })
                    }}>
                    <FormControlLabel
                      value={ReceiptReversalReason.FAILED_ACH}
                      control={<Radio />}
                      label="Failed ACH"
                    />
                    <FormControlLabel
                      value={ReceiptReversalReason.BOUNCED_CHECK}
                      control={<Radio />}
                      label="Bounced Check"
                    />
                    <FormControlLabel
                      value={ReceiptReversalReason.INCORRECTLY_ENTERED}
                      control={<Radio />}
                      label="Incorrectly Entered"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postReceiptReversalParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking ||
                    !postReceiptReversalParams.reversalReason ||
                    !postReceiptReversalParams.reversalDate
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doPostReceiptReversal}>
                  Void Receipt
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
