import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimFunding } from '../../../actions/ZClaimFundingActions'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewClaimDetailOverlay from './ViewClaimDetailOverlay'
import { ShapeFundingRecord } from '../types'
import * as CollectionPicker from './Collections/Picker'
//@ts-ignore
import styled from 'styled-components'
import {
  EmployerSearcher,
  OrganizationSearcherV2,
} from '../../../components/Searchers'
import dateTime from '../../../utils/dateTime'
import DisplayStatus from './Status'
import { scopedRoutes } from '../../../actions/ZClaimActions'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-funding-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);
      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;
        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }
        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  // hacks to tune up pagination controls when not supporting "of <N> records" (counts)
  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-selectRoot {
        margin-right: 0;
      }
      .MuiTablePagination-caption:nth-of-type(2) {
        display: none;
      }
      .MuiButtonBase-root:nth-of-type(4) {
        display: none;
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ViewClaim: {
    name: '',
    details: {
      dataFormat(_: any, row: ShapeFundingRecord): any {
        return <InlineViewClaim data={row} />
      },
    },
  },
  ZClaimID: { name: 'ClaimID', details: { sortName: 'zclaim.id' } },
  ServiceDateFrom: {
    name: 'DOS',
    details: {
      sortName: 'zclaim.first_service_date',
      dataFormat(_: any, row: ShapeFundingRecord): any {
        return (
          <div style={{ minWidth: 100 }}>
            {dateTime.parse(row.ServiceDateFrom).format()}
          </div>
        )
      },
    },
  },
  ServiceDateTo: {
    name: 'DOS Thru',
    details: {
      sortName: 'zclaim.last_service_date',
      dataFormat(_: any, row: ShapeFundingRecord): any {
        return (
          <div style={{ minWidth: 100 }}>
            {dateTime.parse(row.ServiceDateTo).format()}
          </div>
        )
      },
    },
  },
  ZClaimStatus: {
    name: 'Status',
    details: {
      sortName: 'zclaim.adjudication_status',
      dataFormat(_: any, row: any): any {
        return (
          <DisplayStatus
            adjStatus={row.AdjudicationStatus}
            isSuspended={row.IsSuspended}
          />
        )
      },
    },
  },
  Member: {
    name: 'Member',

    details: {
      sortName: 'zclaim.member_name',
      dataFormat(_: any, row: ShapeFundingRecord): any {
        return (
          <div style={{ minWidth: 180 }}>
            <div>{row.PatientName}</div>
            <small>Subscriber: {row.SubscriberName}</small>
          </div>
        )
      },
    },
  },
  EmployerName: {
    name: 'Employer',
    details: { sortName: 'zclaim.employer_name' },
  },
  Provider: {
    name: 'Provider',
    details: {
      sortName: 'zclaim.provider_name',
      dataFormat(_: any, row: ShapeFundingRecord): any {
        return (
          <div style={{ minWidth: 220 }}>
            <div>{row.OrgName}</div>
            <small>Facility: {row.PracticeFacilityName}</small>
          </div>
        )
      },
    },
  },
  TaxID: { name: 'Tax ID', details: { sortName: 'zclaim.tin' } },
  Billed: {
    name: 'Billed',
    details: {
      sortName: 'zclaim.sum_billed',
      dataFormat(_: any, row: ShapeFundingRecord): string {
        return formatAmount(row.BilledAmount)
      },
    },
  },
  Payable: {
    name: 'Payable',
    details: {
      sortName: 'zclaim.sum_payable',
      dataFormat(_: any, row: ShapeFundingRecord): any {
        let signNeg = false
        let style = {}
        if (row.IsReversal) {
          signNeg = true
          style = { color: 'red' }
        }

        return (
          <span style={style}>{formatAmount(row.PayableAmount, signNeg)}</span>
        )
      },
    },
  },
})

const defaultSort = { col: 'zclaim.id', dir: 'desc' }

export const Table = forwardRef(function FundingTable(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimFunding,
    dataTableProps,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        ...dataTableProps,
        isRowCheckable: (row: ShapeFundingRecord) => {
          return !row.IsSuspended
        },
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-funding-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string, asNegative: boolean = false): string {
  if (!amount || amount === '0') return '$0.00'
  let neg = ''
  if (asNegative) neg = '-'
  return `$${neg}${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewClaim({
  data,
}: {
  data: ShapeFundingRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewClaimDetailOverlay
        ref={ref}
        zClaimID={data.ZClaimID}
        onClose={() => refresh()}
        readOnly
      />
    </>
  )
}

export function FilterOrganization(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      selectedOrganizationID={filter.organizationIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          setFilterImmediate({ organizationIDs: [] })
          return
        }
        setFilterImmediate({ organizationIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        label: 'Provider',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
      apiEndpointGetByID={scopedRoutes.getOrganizationByID}
      apiEndpointSearch={scopedRoutes.findOrganizations}
    />
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
      apiEndpointGetByID={scopedRoutes.getEmployerByID}
      apiEndpointSearch={scopedRoutes.findEmployers}
    />
  )
}

// Filter by collections (ie. files where the claims originated from)
export function FilterCollections({
  multiple = false,
  passFilters = null,
  ...remaining
}: Partial<CollectionPicker.Props>): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function handleChange(collectionIDs: number[]) {
    setFilterImmediate({ collectionIDs })
  }

  return (
    <CollectionPicker.Picker
      {...remaining}
      multiple={multiple}
      passFilters={passFilters}
      currentCollectionIDs={filter.collectionIDs || []}
      handleChange={handleChange}
    />
  )
}
