import React, { useState } from 'react'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'
import { invoiceableRecord } from './InvoiceableTable'
import ViewInvoiceableClaim from './ViewInvoiceableClaim'

type props = DrawerProps & {
  data: invoiceableRecord
}

export default React.forwardRef<props, any>(function (
  { data, disableEscapeKeyDown = true, onClose: propOnClose, ...spread }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    setComponent(
      <ViewInvoiceableClaim
        employerID={data.EmployerID}
        zClaimIDs={data.ZClaimIDs}
      />
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})
