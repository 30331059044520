import * as api from '../services/thezerocard/api-helper'

export function getInvoiceReceiptDetail(invoiceID: number) {
  return api
    .get(`/accounting/invoice_receipt_detail/${invoiceID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getRefundInvoiceDetail(invoiceID: number) {
  return api
    .get(`/accounting/refund_invoice_detail/${invoiceID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findZClaimInvoiced(params: any) {
  return api.search('/accounting/invoiced', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function findOpenPayables(params: any) {
  return api.search('/accounting/open_payables', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getPayable(payEntityID: number, status: string) {
  return api
    .get(`/accounting/payable/pay_entity/${payEntityID}/status/${status}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findPayments(params: any) {
  return api.search('/accounting/payments', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getPayment(paymentID: string) {
  return api.get(`/accounting/payment/${paymentID}`).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getEmployerReceiptDetail(receiptID: number) {
  return api
    .get(`/accounting/employer_receipt_detail/${receiptID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getProviderReceiptDetail(receiptID: number) {
  return api
    .get(`/accounting/provider_receipt_detail/${receiptID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getProviderReceiptFiles(receiptID: number) {
  return api
    .get(`/accounting/provider_receipt_files/${receiptID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function deleteProviderReceiptFile(receiptID: number, fileID: number) {
  return api
    .del(`/accounting/provider_receipt_files/${receiptID}/file/${fileID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findReceipts(params: any) {
  return api.search('/accounting/receipts', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type PostApplyEmployerReceiptRecord = {
  receiptID: number
  zClaimInvoiceID: number
  date: string | null
  amount: string
}

export type PostApplyEmployerReceiptsParams = PostApplyEmployerReceiptRecord[]

export function postApplyEmployerReceipts(
  params: PostApplyEmployerReceiptsParams
) {
  return api
    .post('/accounting/apply_employer_receipts', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export enum receiptType {
  CHECK = 'check',
  ACH = 'ach',
}

export type PostEmployerReceiptRecord = {
  employerID: number
  receiptDate: string | null
  receiptType: receiptType
  checkNumber: string
  achInfo: string
  amount: string
  notes: string
}

export type PostEmployerReceiptsParams = PostEmployerReceiptRecord[]

export function postEmployerReceipts(params: PostEmployerReceiptsParams) {
  return api.post('/accounting/employer_receipt', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type PostApplyProviderReceiptRecord = {
  receiptID: number
  zClaimID: number
  date: string | null
  amount: string
}

export type PostApplyProviderReceiptsParams = PostApplyProviderReceiptRecord[]

export function postApplyProviderReceipts(
  params: PostApplyProviderReceiptsParams
) {
  return api
    .post('/accounting/apply_provider_receipts', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export type PostProviderReceiptRecord = {
  billingEntityID: number
  receiptDate: string | null
  receiptType: receiptType
  checkNumber: string
  achInfo: string
  amount: string
  fileIDs: number[]
  notes: string
}

export type PostProviderReceiptsParams = PostProviderReceiptRecord[]

export function postProviderReceipts(params: PostProviderReceiptsParams) {
  return api.post('/accounting/provider_receipt', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export enum ReceiptReversalReason {
  BLANK = '',
  BOUNCED_CHECK = 'bounced_check',
  FAILED_ACH = 'failed_ach',
  INCORRECTLY_ENTERED = 'incorrectly_entered',
}

export type PostReceiptReversalParams = {
  reverseReceiptID: number
  reversalDate: string | null
  reversalReason: ReceiptReversalReason
  notes: string
}

export function postReceiptReversal(params: PostReceiptReversalParams) {
  return api.post('/accounting/receipt_reversal', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type PostRecordInvoiceAchRecord = {
  invoiceID: number
  amount: string
  achInfo: string
}

export type PostRecordInvoiceAchParams = {
  achDate: string | null
  achRecords: PostRecordInvoiceAchRecord[]
}

export function postRecordInvoiceAch(params: PostRecordInvoiceAchParams) {
  return api.post('/accounting/record_invoice_ach', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type PayItem = {
  zClaimID: number
  payEntityID: number
  amount: string
}

export type PayItems = PayItem[]

export function postSendZelisPayments(params: PayItems) {
  return api
    .post('/accounting/send_zelis_payments', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export enum ManualPaymentType {
  ACH = 'ach',
  CHECK = 'check',
}

export type PostSendManualPaymentParams = {
  paymentDate: string | null
  manualPayType: ManualPaymentType
  checkNumber: number | null
  achInfo: string | null
  sendEOP: boolean
  notes: string | null
  payItems: PayItem[]
}

export function postSendManualPayment(params: PostSendManualPaymentParams) {
  return api
    .post('/accounting/send_manual_payment', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export type PostVoidPaymentParams = {
  voidDate: string | null
  notes: string | null
}

export function postVoidPayment(
  paymentID: string,
  params: PostVoidPaymentParams
) {
  return api
    .post(`/accounting/void_payment/${paymentID}`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export type PostVoidInvoiceParams = {
  voidDate: string | null
  notes: string | null
}

export function postVoidInvoice(
  invoiceID: number,
  params: PostVoidInvoiceParams
) {
  return api
    .post(`/accounting/void_invoice/${invoiceID}`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export type PostEmployerRefundParams = {
  zClaimIDs: number[]
  manualPayType: ManualPaymentType
  paymentAmount: string
  paymentDate: string | null
  checkNumber: number | null
  achInfo: string | null
  notes: string | null
}

export function postEmployerRefund(params: PostEmployerRefundParams) {
  return api.post('/accounting/employer_refund', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type postInvoiceParams = {
  invoiceDate: string | null
  zClaimIDs: number[]
}

export function postInvoice(params: postInvoiceParams) {
  return api.post('/accounting/zclaims_invoice', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function postProcess(params: any) {
  return api.post('/accounting/zclaims_process', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type PostSendInvoiceNotificationsParams = {
  invoiceIDs: number[]
}

export function postSendInvoiceCreatedNotification(
  params: PostSendInvoiceNotificationsParams
) {
  return api
    .post('/accounting/send_invoice_created_notifications', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export type WriteoffDetail = {
  zClaimID: number
  amount: string
}

export type PostWriteoffFarleyUncollectibleParams = {
  notes: string | null
  details: WriteoffDetail[]
}

export function postWriteoffFarleyUncollectible(
  params: PostWriteoffFarleyUncollectibleParams
) {
  return api
    .post('/accounting/writeoff_farley_uncollectible', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function postSendInvoiceReminderNotification(
  params: PostSendInvoiceNotificationsParams
) {
  return api
    .post('/accounting/send_invoice_reminder_notifications', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getInvoiceNotifications(invoiceID: number) {
  return api
    .get(`/accounting/invoice_notifications/${invoiceID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getInvoiceFiles(invoiceID: number) {
  return api
    .get(`/accounting/zclaims_invoice/${invoiceID}/files`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findZClaimFunding(params: any) {
  return api.search('/zclaims/funding', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getInvoiceCalcsForZClaim(zClaimID: number) {
  return api
    .get(`/zclaims/zclaims_invoice_calcs/${zClaimID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findZClaimFees(params: any) {
  return api.search(`/zclaims/zclaim_fees`, params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function findInvoiceZClaimFees(params: any) {
  return api.search(`/zclaims/invoice_zclaim_fees`, params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function findZClaimInvoiceable(params: any) {
  return api.search('/zclaims/invoiceable', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function postSendBackToAdjudication(params: any) {
  return api
    .post('/zclaims/funding/back_to_adjudication', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function postTransferPayablesBillingEntity(params: any) {
  return api.post('/accounting/transfer_payables', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export type postDoNotInvoiceParams = {
  zClaimIDs: number[]
  notes: string
}

export function postDoNotInvoice(params: postDoNotInvoiceParams) {
  return api
    .post('/accounting/zclaims/do_not_invoice', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}
