import React, { ReactElement, useMemo, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  postRecordInvoiceAch,
  PostRecordInvoiceAchParams,
  PostRecordInvoiceAchRecord,
} from '../../../actions/ZClaimFundingActions'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import {
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../../hooks/useSnackbar'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { ArrowDownward } from '@material-ui/icons'
import dateTime from '../../../utils/dateTime'
import perms from '../../../utils/perms'

interface props {
  invoicedRecords: invoicedRecord[]
  onComplete: () => void
  disabled: boolean
}

export default function ButtonRecordAutoDebitACH({
  invoicedRecords,
  onComplete,
  disabled = false,
}: props): ReactElement {
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [achDate, setAchDate] = useState<string | null>(null)
  const [achInfoByInvoiceID, setAchInfoByInvoiceID] = useState<{
    [key: number]: string
  }>({})
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )
  const markDisabled = disabled || isWorking || !hasPerms

  // @todo: SD-2563: special attention here
  const achDateSetter = ({ name, value }: SetterArgs) => {
    if (!value || value === '_invalid_') {
      setAchDate(null)
      return
    }
    setAchDate(value)
  }

  const handleNoteChange = ({ name, value }: SetterArgs) =>
    setAchInfoByInvoiceID((curr) => ({
      ...curr,
      [parseInt(name.split('-')[1])]: value,
    }))

  const calculateTotalAmount = (records: invoicedRecord[]): number => {
    return records.reduce(
      (total, record) => total + parseFloat(record.InvoiceBalance),
      0
    )
  }

  const totalAmount = useMemo(
    () => calculateTotalAmount(invoicedRecords),
    [invoicedRecords]
  )

  function fillAllNotesFields(value: string) {
    let newACHInfo: { [key: number]: string } = {}

    for (let record of invoicedRecords) {
      newACHInfo[record.InvoiceID] = value
    }

    setAchInfoByInvoiceID(newACHInfo)
  }

  function doPostRecordInvoiceAch() {
    if (achDate === null) {
      showSnackbar('ACH Date must be a valid date', SnackbarTypeError)
      return
    }

    setIsWorking(true)

    const records: PostRecordInvoiceAchRecord[] = invoicedRecords.map(
      (record) => ({
        invoiceID: record.InvoiceID,
        amount: record.InvoiceBalance,
        achInfo: achInfoByInvoiceID[record.InvoiceID] || '',
      })
    )

    const params: PostRecordInvoiceAchParams = {
      achDate,
      achRecords: records,
    }

    postRecordInvoiceAch(params)
      .then(() => {
        showSnackbar('Record Auto Debit ACH completed OK', SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed recording invoice ACH',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  return (
    <>
      <Button
        fullWidth
        disabled={markDisabled}
        variant="outlined"
        color="primary"
        size="small"
        style={{ height: '50px' }}
        onClick={() => {
          if (!hasPerms) {
            showSnackbar('Insufficient permissions', SnackbarTypeWarning)
            return
          }
          fillAllNotesFields('')
          setAchDate(null)
          setIsOpen(true)
        }}>
        {'Record Auto Debit ACH' +
          (disabled
            ? ''
            : ` (${invoicedRecords.length}) ${formatAmount(totalAmount)}`)}
      </Button>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}>
        <Container>
          <DialogTitle>
            Record Auto Debit ACH
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={4}>
                <Typography>
                  Total Amount: {formatAmount(totalAmount)}
                </Typography>
                <Typography>Invoice Count: {invoicedRecords.length}</Typography>
              </Grid>

              <Grid item xs={2}>
                <ManagedDateInput
                  disabled={isWorking}
                  name="achDate"
                  label="ACH Date"
                  setter={achDateSetter}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ width: '1080px' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2}>
                <Typography>Invoice Number</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Invoice Date</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Employer</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Balance</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>ACH Info</Typography>
              </Grid>
              {invoicedRecords.map((record, index) => (
                <React.Fragment key={record.InvoiceID}>
                  <Grid item xs={2}>
                    <Typography>{record.InvoiceNumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {dateTime.parse(record.InvoiceDate).format()}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <div>
                      <div>{record.EmployerName}</div>
                      {record.EmployerName !==
                        record.EmployerQuickbooksHandle && (
                        <div
                          style={{
                            fontSize: '0.875em',
                            fontStyle: 'italic',
                            color: 'secondary',
                          }}>
                          {record.EmployerQuickbooksHandle}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>
                      {formatStringAmount(record.InvoiceBalance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingTop: '0px' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={9}>
                        <RenderTextField
                          use2023Styles={true}
                          disabled={isWorking}
                          name={`notes-${record.InvoiceID}`}
                          label="ACH info"
                          value={achInfoByInvoiceID[record.InvoiceID] || ''}
                          setter={handleNoteChange}
                        />
                      </Grid>
                      {index === 0 && invoicedRecords.length > 1 && (
                        <Grid item xs={3}>
                          <DesignSuite2023.Tooltip
                            title={'Fill value to all other rows'}>
                            <IconButton
                              disabled={isWorking}
                              onClick={() => {
                                fillAllNotesFields(
                                  achInfoByInvoiceID[record.InvoiceID]
                                )
                              }}>
                              <ArrowDownward fontSize="small" />
                            </IconButton>
                          </DesignSuite2023.Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <>
                  <Button
                    disabled={isWorking}
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setIsOpen(false)
                    }}>
                    Cancel
                  </Button>
                </>
              }
              right={
                <>
                  <>
                    <Button
                      disabled={
                        isWorking ||
                        achDate === null ||
                        Object.values(achInfoByInvoiceID).some(
                          (note) => note === ''
                        )
                      }
                      color="primary"
                      variant="outlined"
                      onClick={doPostRecordInvoiceAch}>
                      Record ACH
                      {isWorking && (
                        <>
                          &nbsp;
                          <DesignSuite2023.LoadingSpinner size={20} show />
                        </>
                      )}
                    </Button>
                  </>
                </>
              }
            />
          </DialogActions>
        </Container>
      </Dialog>
    </>
  )
}

function formatAmount(amount: number): string {
  if (!amount || amount === 0) return '$0.00'
  return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
function formatStringAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
